<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" no-close-on-backdrop no-close-on-esc :title="$t('Update Product', 'Update Product')" @close="close()">
			<template #content>
				<validation-observer ref="form_add_product_priority" tag="form" @submit.prevent="save">
					<ktv-input v-model="localProduct.ptPremiumProductId" disabled readonly :label="$t('ID', 'ID')" />
					<ktv-input v-model="localProduct.productName" disabled readonly :label="$t('Product Name', 'Product Name')" />
					<ktv-input v-model="localProduct.unitInfo" disabled readonly :label="$t('Unit Type', 'Unit Type')" />
					<ktv-select v-model="localProduct.priority" :options="priorityOptions" rules="required" :label="$t('Priority', 'Priority')" />
				</validation-observer>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Save', 'Save')" :disabled="is.loading" :loading="is.loading" icon="save" @click="save()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvInput, KtvModal, KtvSelect } from "@/components"
	import { map } from "underscore"
	import { mapActions, mapState } from "vuex"
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "PremiumPointsAOPackageDetailBasicDataProductUpdate",
		components: { KtvButton, KtvInput, KtvModal, KtvSelect, ValidationObserver },
		props: {
			product: {
				default: () => {},
				required: false,
				type: Object,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-save"],
		data() {
			return {
				is: {
					loading: false,
					showingModal: false,
				},
				localProduct: null,
				priorityOptions: [],
			}
		},
		computed: {
			...mapState("OTHERS", ["priorityList"]),
		},
		watch: {
			priorityList: {
				deep: true,
				handler() {
					this.getPriorityOptions()
				},
				immediate: true,
			},
			product: {
				deep: true,
				handler(product) {
					this.localProduct = Object.assign(
						{
							priority: null,
						},
						product,
					)
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		created() {
			this.getPriorityList()
		},
		methods: {
			...mapActions({
				getPriorityList: "OTHERS/getPriorityList",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			getPriorityOptions() {
				this.priorityOptions = map(this.priorityList, (priority) => {
					return {
						text: priority.text,
						value: priority.value,
					}
				})
			},
			save() {
				const self = this
				this.is = {
					...this.is,
					loading: true,
				}

				this.$refs.form_add_product_priority.validate().then((valid) => {
					if (valid) {
						self.$emit("on-save", self.localProduct)
						self.$emit("close")
					} else {
						self.is = {
							...self.is,
							loading: false,
						}
					}
				})
			},
		},
	}
</script>
