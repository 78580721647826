<template>
	<div>
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this data?', 'Do you want to delete this data?')"
			:title="$t('Delete Data', 'Delete Data')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deleteData()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<basic-data-product-update-modal :product="product" :show="is.showing.updateModal" @close="closeUpdateModal" @on-save="updateProduct" />
		<ktv-form :label="$t('AO Package Setting Basic Data View', 'AO Package Setting Basic Data View')" @submit="() => {}">
			<template #content>
				<b-row>
					<ktv-select v-model="localPackage.district" :options="districtOptions" rules="required" :label="$t('District', 'District')" disabled />
				</b-row>
				<b-row>
					<ktv-select v-model="localPackage.aoNumber" :options="aoNumberOptions" rules="required" :label="$t('AO Number', 'AO Number')" disabled />
				</b-row>
				<b-card
					:header="$t('Product List', 'Product List')"
					align="left"
					border-variant="primary"
					class="mt-4 mb-2"
					header-bg-variant="primary"
					header-text-variant="white"
					no-body
				/>
				<ktv-table
					id="basic-data"
					:columns="columns"
					:filter="false"
					:is-loading="is.loading"
					:rows="filteredData"
					:search-enabled="false"
					:search-on-enter="false"
					:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
					:total-rows="aoPackageSettingProductDetail ? filteredData.length : 0"
					min-height="250px"
					mode="client"
					@on-search="onSearch"
				>
					<template #columns="{ props }">
						{{ $t(props.column.label, props.column.label) }}
					</template>
					<template #rows="{ props }">
						<span v-if="props.column.field === 'button'">
							<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
								<template #button-content>
									<img :src="icons.dropdown" />
								</template>
								<b-dropdown-item @click="showUpdateModal(props.row)">
									<img :src="icons.edit" class="imgicon" />{{ $t("Update", "Update") }}
								</b-dropdown-item>
								<b-dropdown-item @click="showDeleteDialog(props.row)">
									<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
								</b-dropdown-item>
							</b-dropdown>
						</span>
						<span v-else-if="props.column.field === 'ptPremiumProductId'">{{
							props.row.ptPremiumProductId ? props.row.ptPremiumProductId : "-"
						}}</span>
						<span v-else-if="props.column.field === 'productName'">{{ props.row.productName ? props.row.productName : "-" }}</span>
						<span v-else-if="props.column.field === 'unitInfo'">{{ props.row.unitInfo ? props.row.unitInfo : "-" }}</span>
						<span v-else-if="props.column.field === 'pricePerUnit'">
							{{ props.row.pricePerUnit >= 0 ? IDRFilter(props.row.pricePerUnit) : "-" }}
						</span>
						<span v-else-if="props.column.field === 'priority'">{{ props.row.priority ? props.row.priority : "-" }}</span>
						<span v-else-if="props.column.field === 'maximumRecomendation'">
							{{ props.row.maximumRecomendation ? props.row.maximumRecomendation : "-" }}
						</span>
					</template>
				</ktv-table>
			</template>
		</ktv-form>
	</div>
</template>

<script>
	import { KtvButton, KtvDialog, KtvForm, KtvSelect, KtvTable } from "@/components"
	import { filter, map, reject } from "underscore"
	import { mapActions, mapState } from "vuex"
	import BasicDataProductUpdateModal from "./modals/_BasicDataProductUpdate"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import editIcon from "@/assets/images/icon/iconedit.png"

	const icons = {
		delete: deleteIcon,
		dropdown: dropdownIcon,
		edit: editIcon,
	}

	export default {
		name: "PremiumPointsAOPackageDetailBasicData",
		components: { BasicDataProductUpdateModal, KtvButton, KtvDialog, KtvForm, KtvSelect, KtvTable },
		data() {
			return {
				aoNumberOptions: [],
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "ptPremiumProductId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "productName",
						label: "Product Name",
						thClass: "text-left",
					},
					{
						field: "unitInfo",
						label: "Unit Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "pricePerUnit",
						label: "Price (/unit)",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "priority",
						label: "Priority",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "maximumRecomendation",
						label: "Max Recommendation",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
				],
				districtOptions: [],
				icons: icons,
				is: {
					loading: false,
					showing: {
						deleteDialog: false,
						updateModal: false,
					},
				},
				localPackage: {
					aoNumber: null,
					district: null,
				},
				product: null,
				searchText: null,
			}
		},
		computed: {
			...mapState("OTHERS", ["aoNumberList", "districtList"]),
			...mapState("PREMIUMPOINT", ["aoPackageSettingProductDetail"]),
			filteredData() {
				return this.searchText
					? filter(this.aoPackageSettingProductDetail, (product) => {
						const productName = String(product.productName).toLowerCase()
						const ptPremiumProductId = String(product.ptPremiumProductId).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return productName.indexOf(searchText) > -1 || ptPremiumProductId.indexOf(searchText) > -1
					})
					: this.aoPackageSettingProductDetail
			},
		},
		watch: {
			aoNumberList: {
				deep: true,
				handler() {
					this.getAONumberOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.is = {
				...this.is,
				loading: true,
			}

			this.localPackage = {
				...this.localPackage,
				aoNumber: this.$route.params.ao,
				district: this.$route.params.district,
			}

			this.getDistrictList()
			this.getAONumberList()
			this.getAOPackageSettingProductDetail({
				district_id: this.$route.params.district,
				ao_number: this.$route.params.ao,
			}).then(() => {
				this.is = {
					...this.is,
					loading: false,
				}
			})
		},
		beforeDestroy() {
			this.localPackage = {
				aoNumber: null,
				district: null,
			}
			this.searchText = null
			this.resetAOPackageSettingProductDetail()
		},
		methods: {
			...mapActions({
				deleteAOPackageSetting: "PREMIUMPOINT/deleteAOPackageSetting",
				getAONumberList: "OTHERS/getAONumberList",
				getAOPackageSettingProductDetail: "PREMIUMPOINT/getAOPackageSettingProductDetail",
				getDistrictList: "OTHERS/getDistrictList",
				resetAOPackageSettingProductDetail: "PREMIUMPOINT/resetAOPackageSettingProductDetail",
				updateAOPackageSettingProductPriority: "PREMIUMPOINT/updateAOPackageSettingProductPriority",
			}),
			closeDeleteDialog() {
				this.product = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}

				this.product = null
			},
			closeUpdateModal() {
				this.product = null

				this.is.showing = {
					...this.is.showing,
					updateModal: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deleteAOPackageSetting({
					params: {
						ao_number: this.$route.params.ao,
						district_id: this.$route.params.district,
					},
					product: this.product,
				}).then(() => {
					this.closeDeleteDialog()
				})
			},
			getAONumberOptions() {
				this.aoNumberOptions = this.aoNumberList
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: district.districtId,
					}
				})

				this.districtOptions = reject(this.districtOptions, (district) => {
					return !district.text || !district.value
				})
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			showDeleteDialog(product) {
				this.product = product

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			showUpdateModal(product) {
				this.product = product

				this.is.showing = {
					...this.is.showing,
					updateModal: true,
				}
			},
			updateProduct(product) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.updateAOPackageSettingProductPriority({
					aoQuestionNumber: this.localPackage.aoNumber,
					params: {
						district_id: this.$route.params.district,
						ao_number: this.$route.params.ao,
					},
					priority: product.priority,
					productName: product.productName,
					productPriorityId: product.ptPremiumPriorityId,
				})
					.then(() => {
						this.closeUpdateModal()
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
		},
	}
</script>
