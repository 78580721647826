<template>
	<div class="main-content">
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			:page="$t('AO Package', 'AO Package')"
			:title="$t('AO Package Setting Detail', 'AO Package Setting Detail')"
		/>
		<b-row>
			<b-col md="12 mb-30">
				<b-row>
					<b-col>
						<h1>{{ $t("AO Package Setting", "AO Package Setting") }}</h1>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col>
						<router-link v-b-tooltip.hover :to="{ name: 'Premium Points AO Package Setting List' }">
							<ktv-back :label="$t('Back to ao package setting list', 'Back to ao package setting list')" />
						</router-link>
					</b-col>
				</b-row>
				<div>
					<b-card
						:header="$t('AO Package Setting Basic Data', 'AO Package Setting Basic Data')"
						align="left"
						border-variant="danger"
						header-bg-variant="primary"
						header-text-variant="white"
					>
						<basic-data />
					</b-card>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import { KtvBack } from "@/components"
	import BasicData from "./_BasicData"

	export default {
		name: "PremiumPointsAOPackageDetail",
		metaInfo: {
			title: "Detail AO Package Setting",
		},
		components: { BasicData, KtvBack },
	}
</script>
